<template>
   <!-- <h1> Танц поле фото</h1> -->

   <p>
    <strong>Current route path:</strong> {{ $route.fullPath }}
  </p>

  <nav>
    <RouterLink to="/">Home</RouterLink>
    
    <RouterLink to="/about">About</RouterLink>
    
    <RouterLink to="/dance">Dance Field</RouterLink>
  </nav>

  <main>
    <RouterView />
  </main>
  
  <!-- <DanceField /> -->
  
</template>

<script>
//import DanceField from './components/DanceField.vue';




export default {
  name: 'App',
  components: {
   // DanceField
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;


}
nav {
  border: 0px solid #c2bdbd;
  margin-bottom: 20px;
  padding: 20px;
}
nav > a + a {
  margin-left: 10px;
}


</style>
