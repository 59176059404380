<template>
    <div >
        {{title}}
        <br>
        <img 
        :src='imgUrl'
        :width ='width'>
        
        
        

    </div>
  
</template>

<script>
export default {
    name: 'PhotoCard',
    props: ['title', 'album', 'imgUrl', 'comments','width'],
    
    // data() {
    //     return {
    //         title: 'Фестиваль',
    //         album: '',
    //         //imgUrl: '../assets/Photo1.jpg',
    //         // Как отобразить локальную картинку, запрос в гугле был 'vue my local images'
    //         // работает
    //         imgUrl: require('../assets/Photo1.jpg'),
    //         comments:'',
    //         width: '400px'

    //     }
    //}

}
</script>

<style>

</style>