<template>
  <div class="user-card">
    <h1> Научу зарабатывать от 50.000 в час</h1>
        <div class="main-information">
                <img src="../assets/man.jpg" width="300px">
                <div class="intro">
                    <h2>
                        {{getAuthorFullName}}
                    </h2>
                    <strong>Бизнес Коуч</strong>
                    <ul>
                        <li>Учу людей делать реальные бабки. На себя просто не хватило времени X</li>
                        <li>Не знаю что такое успешный успех, но знаю что нужно тебе!</li>
                        <li>Пообещал что побреюсь. Когда нибудь обману</li>
                    </ul>

                </div>

        </div>
        <p>Участников {{users.length}} </p>
        
        <ul>
            <li v-for="(user, index) in users" :key="index">
               {{index+1}}  - {{getFullName(user)}}
            </li>
        </ul>
        <!-- ****************************************
        <br>
        <ul>
            <li v-for="(value, key_, index) in users[0]" :key="index">
               {{value}}  - {{key_}}  - {{index}} 
            </li>
        </ul>
        **************************************** -->
        <button type="button" @click="currentPage--">
            Пред
        </button>

        <button v-for="page in pages" :key="page" type="button" @click="currentPage=page">
            {{ page }}
        </button>

        <button type="button" @click="currentPage++">
            След
        </button>
        <p> Страница {{currentPage }} из {{pages}}</p>
  </div>
</template>

<script>


export default {
    name: 'UserCard',
    data() {
        return {
            firstName: "Игнатий",
            secondName: 'Илларионович',
            lastName: 'Дорогобогатов',
            users: [
                {
                    firstName: "Иван",
                    secondName: 'Илларионович',
                    lastName: 'Иванов'
                },
                {
                    firstName: "Иван",
                    secondName: 'Илларионович',
                    lastName: 'Иванов'
                },
                {
                    firstName: "Иван",
                    secondName: 'Илларионович',
                    lastName: 'Иванов'
                }

            ],
            pages: 3,
            currentPage: 1
        }
    },
        computed: {
            getAuthorFullName() {
                return this.firstName.toUpperCase()+' '+this.secondName.toUpperCase()+' '+this.lastName.toUpperCase()
            }
        },
        methods: {
            getFullName(user) {
                return user.firstName + ' ' + user.secondName +' '+ user.lastName //'S{user.firstName} S{user.secondName} S{user.lastName}'
            }
        },
        loadusers(page) {
            console.log('Загрузка пользователей страница' + page)
        },
        watch: {
            currentPage(newValue, oldValue) {
                console.log('newValue ', newValue)
                console.log('oldValue ', oldValue)
                this.loadusers(newValue)
            }
        }

}
</script>

<style>

</style>