<template>
 


  <ul class="list-defailt" flex>
    <li
    v-for="(photo, index) in PhotoList"
    :key="index"
    >
   
    <PhotoCard
    :title = "photo.title" 
    :album="photo.album"
    :imgUrl = "photo.imgUrl"
    :comments = "photo.comments"
    :width = "photo.width"
    
    />
    
    </li>
  </ul>
</template>

<script>
import PhotoCard from './PhotoCard.vue';


export default {
    name: 'DanceField',
    components: {PhotoCard},
    data() {
        return {
            PhotoList: [
            {
                title:'Первая картинка',
                album: '',
                imgUrl: require('../assets/Photo1.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Вторая картинка',
                album: '',
                imgUrl: require('../assets/Photo2.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Третья картинка',
                album: '',
                imgUrl: require('../assets/Photo3.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Четвертая картинка',
                album: '',
                imgUrl: require('../assets/Photo4.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Пятая картинка',
                album: '',
                imgUrl: require('../assets/Photo5.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Шестая картинка',
                album: '',
                imgUrl: require('../assets/Photo6.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Седьмая картинка',
                album: '',
                imgUrl: require('../assets/Photo7.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Восьмая картинка',
                album: '',
                imgUrl: require('../assets/Photo8.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Девятая картинка',
                album: '',
                imgUrl: require('../assets/Photo9.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Десятая картинка',
                album: '',
                imgUrl: require('../assets/Photo10.jpg'),
                comments:'',
                width: '400px'
            },
            {
                title:'Одиннадцатая картинка',
                album: '',
                imgUrl: require('../assets/Photo11.jpg'),
                comments:'',
                width: '400px'
            },


        ]
        }
        
    }

}
</script>

<style>

</style>