<template>
  About View

    <UserCard />
</template>

<script>

import UserCard from './UserCard.vue';
export default {
  components: {UserCard}

}
</script>

<style>

</style>